



































































































import { Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import InfiniteLoading, { StateChanger } from 'vue-infinite-loading';
import { ITeacher } from '@/types/teacher';
import { IEducationGroup } from '@/types/education-group';
import { store } from '@/store';
import VideoCard from '@/components/cards/VideoCard.vue';
import SortButton from '@/components/SortButton.vue';

@Component({
  components: {
    VideoCard,
    InfiniteLoading,
    SortButton,
  },
})
export default class LessonsPage extends Vue {
  protected filterMyTeachers = false;
  protected filterMyLessons = false;

  async mounted() {
    await store.main.actions.fetchTeamLeadersWithLessons();
    await store.main.actions.fetchTeachersWithLessons();
    await store.main.actions.fetchEducationGroupWithLessons();
    await store.main.actions.fetchLessonsByFilter();
  }

  /**
   * Состояние фильра по тим-лидерам
   */
  get filterTeamLeader(): ITeacher | null | undefined {
    return store.main.state.filterTeamLeader;
  }
  set filterTeamLeader(val: ITeacher | null | undefined) {
    store.main.mutations.setTeamLeader(val);
  }
  /**
   * Состояние фильра по учителям
   */
  get filterTeacher(): ITeacher | null | undefined {
    return store.main.state.filterTeacher;
  }
  set filterTeacher(val: ITeacher | null | undefined) {
    store.main.mutations.setTeacherFilter(val);
  }

  /**
   * Состояние фильра по группам
   */
  get filterGroup(): IEducationGroup | null {
    return store.main.state.filterGroup;
  }
  set filterGroup(val: IEducationGroup | null) {
    store.main.mutations.setGroupFilter(val);
  }

  /**
   * Состояние фильтра порядка по дате
   */
  get filterOrder(): string {
    return store.main.state.filterOrder;
  }
  set filterOrder(val: string) {
    store.main.mutations.setOrderFilter(val);
  }

  get lessons() {
    return store.main.state.lessons;
  }

  get teamLeaders() {
    return store.main.state.teamLeadersWithLessons;
  }

  get teachers() {
    return store.main.getters.teachers;
  }

  get educationGroups() {
    return store.main.getters.educationGroups;
  }

  get getStateScroll() {
    return store.main.getters.getStateScroll;
  }

  async selectTeamLeader() {
    if (typeof this.filterTeamLeader === 'string') return;
    this.filterGroup = null;
    this.filterTeacher = null;
    this.filterMyLessons = false;
    store.main.actions.fetchLessonsByFilter();
    if (this.filterTeamLeader) {
      if (this.filterTeamLeader.email !== store.auth.state.user?.email) this.filterMyTeachers = false;
      await store.main.actions.fetchTeachersByTeamLeader(this.filterTeamLeader);
      await store.main.actions.fetchEducationGroupsByTeamLeader(this.filterTeamLeader);
    } else this.filterMyTeachers = false;
  }

  async selectTeacher() {
    if (typeof this.filterTeacher === 'string') return;
    this.filterGroup = null;
    this.filterMyTeachers = false;
    store.main.actions.fetchLessonsByFilter();
    if (this.filterTeacher) {
      if (this.filterTeacher.email !== store.auth.state.user?.email) this.filterMyLessons = false;
      await store.main.actions.fetchEducationGroupsByTeacher(this.filterTeacher);
    } else this.filterMyLessons = false;
    if (this.filterTeacher?.teamLeader) {
      this.filterTeamLeader = this.filterTeacher.teamLeader;
      await store.main.actions.fetchTeachersByTeamLeader(this.filterTeacher.teamLeader);
    }
  }

  async selectGroup() {
    if (typeof this.filterGroup === 'string') return;
    await store.main.actions.fetchLessonsByFilter();
    if (this.filterGroup?.teacher?.teamLeader) {
      this.filterTeamLeader = this.filterGroup.teacher.teamLeader;
      await store.main.actions.fetchTeachersByTeamLeader(this.filterGroup.teacher.teamLeader);
    }
    if (this.filterGroup?.teacher) {
      this.filterTeacher = this.filterGroup.teacher;
      await store.main.actions.fetchEducationGroupsByTeacher(this.filterGroup.teacher);
    }
  }

  async selectOrder(order: string) {
    if (this.filterOrder === order) return;
    this.filterOrder = order;
    await store.main.actions.fetchLessonsByFilter();
  }

  async selectMyTeachers() {
    if (!this.filterMyTeachers) this.filterTeamLeader = null;
    else {
      this.filterTeamLeader = this.teamLeaders.find(({ email }) => store.auth.state.user?.email === email);
      if (!this.filterTeamLeader) {
        this.$notify({ type: 'info', content: 'У вас нет проверенных уроков', snackbar: { timeout: 1000 } });
        setTimeout(() => {
          this.filterMyTeachers = false;
        }, 1000);
        return;
      }
    }
    await this.selectTeamLeader();
  }

  async selectMyLessons() {
    if (!this.filterMyLessons) this.filterTeacher = null;
    else {
      this.filterTeamLeader = null;
      this.filterTeacher = this.teachers.find(({ email }) => store.auth.state.user?.email === email);
      if (!this.filterTeacher) {
        this.$notify({ type: 'info', content: 'У вас нет проверенных уроков' });
        setTimeout(() => {
          this.filterMyLessons = false;
        }, 1000);
        return;
      }
    }
    await this.selectTeacher();
  }

  /**
   * При скролле страницы подгружает еще уроки
   */
  fetchNextLessons($InfiniteScrollState: StateChanger) {
    if (!this.getStateScroll) {
      setTimeout(() => {
        store.main.actions
          .addLessonsByFilter()
          .then((data: any) => {
            if (!data.length || data.length < 30) {
              $InfiniteScrollState.complete();
            } else $InfiniteScrollState.loaded();
          })
          .catch(() => $InfiniteScrollState.complete());
      }, 1000);
    }
  }
}
